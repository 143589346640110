<template>
  <div class="layout-config h-100 p-20">
    <h2 class="p-10 text-c">{{ view.newTitle }}</h2>
    <Button type="error" size="small" to="/config/dataManage/researchNews" class="extra">返回</Button>
    <div class="research-news-content">
      <div class="text-r" v-if="view.tag"><Tag>{{ view.tag }}</Tag></div>
      <div class="p-10 research-news" v-html="view.newContent"></div>
      <div class="text-r m-t-10">
        <div> {{ view.newOrg }} </div>
        <div> {{ view.newEdit }} </div>
        <div>{{ view.newDate }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { getNewsById } from '@/libs/api/researchNews'
export default {
    data() {
        return {
            view: {}
        }
    },
    methods: {

    },
    async created() {
        const { id } = this.$route.query
        if(id) {
            const { code, data } = await getNewsById({ new_id: id})
            if(code == "HA0200") {
                this.view = data
            }
        }
    }
}
</script>

<style lang="less" scoped>
.layout-config {
  position: relative;
  .extra {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .research-news-content {
    width: 1000px;
    margin: 0 auto;
  }
  .research-news {
    text-indent: 2em;
  }
}
</style>